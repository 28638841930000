const menuItems = {
  items: [
    {
      id: 'order',
      title: '국내자동주문',
      type: 'group',
      icon: 'icon-support',
      children: [
        {
          id: 'Reservation',
          title: '예약내역',
          type: 'item',
          url: '/Reservation',
          classes: 'nav-item',
          icon: 'feather icon-layers'
        },
        {
          id: 'transaction-page',
          title: '결제내역',
          type: 'item',
          url: '/transaction-page',
          classes: 'nav-item',
          icon: 'feather icon-plus'
        },
        {
          id: 'stock-detail-page',
          title: '보유내역 및 대사',
          type: 'item',
          url: '/stock-detail-page',
          classes: 'nav-item',
          icon: 'feather icon-package'
        },
        {
          id: 'stock-profit-page',
          title: '수익내역',
          type: 'item',
          url: '/stock-profit-page',
          classes: 'nav-item',
          icon: 'feather icon-trending-up'
        }

      ]
    },
    // {
    //   id: 'exchange',
    //   title: '환전관리',
    //   type: 'group',
    //   icon: 'icon-support',
    //   children: [
    //     {
    //       id: 'exchange',
    //       title: '환전내역',
    //       type: 'item',
    //       url: '/exhange-page',
    //       classes: 'nav-item',
    //       icon: 'feather icon-shuffle'
    //     },
    //   ]
    // },
    {
      id: 'over_order',
      title: '미국자동주문',
      type: 'group',
      icon: 'icon-support',
      children: [
        {
          id: 'us-reservation',
          title: '예약내역',
          type: 'item',
          url: '/us-reservation',
          classes: 'nav-item',
          icon: 'feather icon-layers'
        },
        {
          id: 'stock-detail-page',
          title: '보유내역 및 대사',
          type: 'item',
          url: '/us-stock-detail-page',
          classes: 'nav-item',
          icon: 'feather icon-package'
        },
      ]
    },
    {
      id: 'over_order',
      title: '일본자동주문',
      type: 'group',
      icon: 'icon-support',
      children: [
        {
          id: 'jp-reservation',
          title: '예약내역',
          type: 'item',
          url: '/jp-reservation',
          classes: 'nav-item',
          icon: 'feather icon-layers'
        },
        {
          id: 'jp-stock-detail-page',
          title: '보유내역 및 대사',
          type: 'item',
          url: '/jp-stock-detail-page',
          classes: 'nav-item',
          icon: 'feather icon-package'
        },
      ]
    },
  ]
};

export default menuItems;
