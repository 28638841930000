import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import Loader from './components/Loader/Loader';
import AdminLayout from './layouts/AdminLayout';

import GuestGuard from './components/Auth/GuestGuard';
import AuthGuard from './components/Auth/AuthGuard';

import { BASE_URL } from './config/constant';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<Loader />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>{route.routes ? renderRoutes(route.routes) : <Component {...props} />}</Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    guard: GuestGuard,
    path: '/auth/signin-1',
    component: lazy(() => import('./views/auth/signin/SignIn1'))
  },
  {
    exact: true,
    path: '/auth/signup-1',
    component: lazy(() => import('./views/auth/signup/SignUp1'))
  },
  {
    exact: true,
    path: '/auth/reset-password-1',
    component: lazy(() => import('./views/auth/reset-password/ResetPassword1'))
  },
  {
    exact: true,
    path: '/auth/profile-settings',
    component: lazy(() => import('./views/auth/ProfileSettings'))
  },
  {
    exact: true,
    path: '/auth/tabs-auth',
    component: lazy(() => import('./views/auth/TabsAuth'))
  },
  {
    path: '*',
    layout: AdminLayout,
    guard: AuthGuard,
    routes: [
      {
        exact: true,
        path: '/Reservation',
        component: lazy(() => import('./views/page/Reservation'))
      },
      {
        exact: true,
        path: '/us-reservation',
        component: lazy(() => import('./views/page/us/UsReservation'))
      },
      {
        exact: true,
        path: '/jp-reservation',
        component: lazy(() => import('./views/page/jp/JpReservation'))
      },
      {
        exact: true,
        path: '/stock-profit-page',
        component: lazy(() => import('./views/page/StockProfitPage'))
      },
      {
        exact: true,
        path: '/us-stock-profit-page',
        component: lazy(() => import('./views/page/us/UsStockProfitPage'))
      },
      {
        exact: true,
        path: '/jp-stock-profit-page',
        component: lazy(() => import('./views/page/jp/JpStockProfitPage'))
      },
      {
        exact: true,
        path: '/transaction-page',
        component: lazy(() => import('./views/page/TransactionPage'))
      },
      {
        exact: true,
        path: '/us-transaction-page',
        component: lazy(() => import('./views/page/us/UsTransactionPage'))
      },
      {
        exact: true,
        path: '/jp-transaction-page',
        component: lazy(() => import('./views/page/jp/JpTransactionPage'))
      },
      {
        exact: true,
        path: '/stock-detail-page',
        component: lazy(() => import('./views/page/StockDetailPage'))
      },
      {
        exact: true,
        path: '/us-stock-detail-page',
        component: lazy(() => import('./views/page/us/UsStockDetailPage'))
      },
      {
        exact: true,
        path: '/jp-stock-detail-page',
        component: lazy(() => import('./views/page/jp/JpStockDetailPage'))
      },
      {
        path: '*',
        exact: true,
        component: () => <Redirect to={BASE_URL} />
      }
    ]
  }
];

export default routes;
